/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useCallback, useEffect, useState } from "react";

import { useRealm } from "../../../../context/realm-context/RealmContext";
import { CreateLdapSchema } from "../CreateLdap";
import { useAlerts } from "../../../../components/alert/Alerts";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";

import { adminClient } from "../../../../admin-client";

import { AlertVariant } from "@patternfly/react-core";
import { useTranslation } from "react-i18next";

export default function useLdap() {
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [tableData, setTableData] = useState<CreateLdapSchema[]>([]);

  const { realm } = useRealm();

  const { t } = useTranslation();

  const { addAlert, addError } = useAlerts();

  const getLdap = useCallback(async () => {
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/ldap-connections/${realmRep?.id}`;

    const ldapResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(await adminClient.getAccessToken()),
      },
    });
    const ldaps = await ldapResponse.json();
    if (ldaps) {
      const ldapsArray: CreateLdapSchema[] = [];

      for (const ldap of ldaps) {
        ldapsArray.push({
          id: ldap.uuid,
          name: ldap.name,
          ldapHost: ldap.ldapHost,
          ldapDomainName: ldap.ldapDomainName,
          ldapPort: ldap.ldapPort,
          ldapBindUsername: ldap.ldapBindUsername,
          ldapBindPassword: ldap.ldapBindPassword,
          ldapOrganizationalUnit: ldap.ldapOrganizationalUnit,
        });
      }

      console.log(ldapsArray);
      return setTableData(ldapsArray);
    }
    return setTableData([]);
  }, [realm]);

  const handleDeleteLdap = async (id: string | null) => {
    if (!id) return;
    try {
      const response = await fetch(
        `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/ldap-connections/${id}`,
        {
          method: "DELETE",
          headers: getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      );
      if (response.ok) {
        addAlert("Success ! Template Deleted", AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    } finally {
      getLdap();
    }
  };

  const handleAreYouSureDelete = (id: string) => {
    setDeleteId(id);
  };

  useEffect(() => {
    getLdap();
  }, [getLdap]);

  return { deleteId, tableData, handleDeleteLdap, handleAreYouSureDelete };
}
