/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useCallback, useEffect, useState } from "react";

import { useRealm } from "../../../../context/realm-context/RealmContext";
import { CreateWebhookSchema, FormattedWebhookSchema } from "../CreateWebhook";
import { useAlerts } from "../../../../components/alert/Alerts";

import { adminClient } from "../../../../admin-client";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";

import { AlertVariant } from "@patternfly/react-core";
import { useTranslation } from "react-i18next";

export default function useIntegrations() {
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [tableData, setTableData] = useState<
    (CreateWebhookSchema & { id: string })[]
  >([]);

  const { realm } = useRealm();

  const { t } = useTranslation();

  const { addAlert, addError } = useAlerts();

  const getIntegrations = useCallback(async () => {
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/integrations/${realmRep?.id}`;
    console.log(realmRep);

    const webhooksResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(await adminClient.getAccessToken()),
      },
    });
    const webhooks = await webhooksResponse.json();
    console.log("WEBHOOKS", webhooks);
    if (webhooks) {
      const webhooksArray: (CreateWebhookSchema & { id: string })[] = [];
      for (const webhook of webhooks) {
        const adminEvents = (() => {
          try {
            const parsed = JSON.parse(webhook.adminEventsListeners);
            return Array.isArray(parsed) ? parsed : [];
          } catch {
            return [];
          }
        })();
        const loginEvents = (() => {
          try {
            const parsed = JSON.parse(webhook.eventsListeners);
            return Array.isArray(parsed) ? parsed : [];
          } catch {
            return [];
          }
        })();
        webhooksArray.push({
          id: webhook.uuid,
          name: webhook.name,
          type: webhook.type,
          url: webhook.url,
          adminEvents: adminEvents.join(", ") ?? [],
          loginEvents: loginEvents.join(","),
        });
      }

      return setTableData(webhooksArray);
    }
    return setTableData([]);
  }, [realm]);

  const handleDeleteWebhook = async (id: string | null) => {
    if (!id) return;
    try {
      const response = await fetch(
        `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/integrations/${id}`,
        {
          method: "DELETE",
          headers: getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      );
      if (response.ok) {
        addAlert("Success ! Template Deleted", AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    } finally {
      getIntegrations();
    }
  };

  const handleAreYouSureDelete = (id: string) => {
    setDeleteId(id);
  };

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  return { deleteId, tableData, handleDeleteWebhook, handleAreYouSureDelete };
}
