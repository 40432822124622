import { useCallback, useEffect } from "react";

import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
  ValidatedOptions,
} from "@patternfly/react-core";

import { ViewHeader } from "../../../../components/view-header/ViewHeader";

import { FormProvider, useForm } from "react-hook-form";

import { FormAccess } from "../../../../components/form/FormAccess";
import { useTranslation } from "react-i18next";
import { KeycloakTextInput } from "../../../../components/keycloak-text-input/KeycloakTextInput";
import { Link, useNavigate, useParams } from "react-router-dom";

import { adminClient } from "../../../../admin-client";
import { useRealm } from "../../../../context/realm-context/RealmContext";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";

import { useAlerts } from "../../../../components/alert/Alerts";

import { CreateLdapSchema } from "../CreateLdap";
import { toLdapRoute } from "../../../routes/ldapRoutes";
import LdapService from "../../../services/ldapService";

export default function EditLdap() {
  const { ldapId } = useParams();
  const form = useForm<CreateLdapSchema>({
    defaultValues: {
      name: "",
      ldapHost: "",
      ldapDomainName: "",
      ldapPort: "",
      ldapBindUsername: "",
      ldapBindPassword: "",
      ldapOrganizationalUnit: "",
    },
  });

  const navigate = useNavigate();
  const { addAlert, addError } = useAlerts();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = form;

  const { realm } = useRealm();

  const { t } = useTranslation("point");

  const onSubmit = async (data: CreateLdapSchema) => {
    if (!ldapId) return;
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/ldap-connections`;
    try {
      const formattedData = {
        uuid: data.id,
        realmId: realmRep?.id,
        name: data.name,
        ldapHost: data.ldapHost,
        ldapDomainName: data.ldapDomainName,
        ldapPort: data.ldapPort,
        ldapBindUsername: data.ldapBindUsername,
        ldapBindPassword: data.ldapBindPassword,
        ldapOrganizationalUnit: data.ldapOrganizationalUnit,
      };

      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(formattedData),
        headers: {
          "Content-Type": "application/json",
          ...getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      });

      navigate(toLdapRoute({ realm }));
      if (response.ok) {
        const successMessage = t("point-ldapEditedSuccessfuly");
        addAlert(successMessage, AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  const getIntegrations = useCallback(async () => {
    if (!ldapId) return;

    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/ldap-connections/${ldapId}/${realmRep?.id}`;
    const ldapResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(await adminClient.getAccessToken()),
      },
    });
    const ldapJson = await ldapResponse.json();
    if (ldapJson) {
      reset({
        id: ldapJson.uuid,
        name: ldapJson.name,
        ldapHost: ldapJson.ldapHost,
        ldapDomainName: ldapJson.ldapDomainName,
        ldapPort: ldapJson.ldapPort,
        ldapBindUsername: ldapJson.ldapBindUsername,
        ldapBindPassword: ldapJson.ldapBindPassword,
        ldapOrganizationalUnit: ldapJson.ldapOrganizationalUnit,
      });
    }
  }, [ldapId, realm, reset]);

  const testLdapConnection = async () => {
    const { ldapBindPassword, ldapBindUsername, ldapHost, ldapPort } =
      getValues();
    const ldapConnectionTestResponse = await LdapService.pointLDAPConnection({
      ldapBindPassword,
      ldapBindUsername,
      ldapHost,
      ldapPort,
    });
    if (ldapConnectionTestResponse) {
      addAlert("Connection successful!", AlertVariant.success);
    } else {
      addAlert("Failed to Test Connection", AlertVariant.danger);
    }
  };

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  return (
    <>
      <ViewHeader titleKey={t("point-editLdapConnections")} />

      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormGroup label={t("point-ldapId")}>
              <KeycloakTextInput isDisabled id="id" value={ldapId} />
            </FormGroup>

            <FormGroup
              label={t("point-ldapName")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.name ? ValidatedOptions.error : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="name"
                data-testid="name"
                validated={
                  errors.name
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("name", { required: true })}
              />
            </FormGroup>

            <FormGroup
              label={t("point-ldapHost")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.ldapHost
                  ? ValidatedOptions.error
                  : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="ldapHost"
                data-testid="ldapHost"
                validated={
                  errors.ldapHost
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("ldapHost", { required: true })}
              />
            </FormGroup>

            <FormGroup
              label={t("point-ldapPort")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.ldapPort
                  ? ValidatedOptions.error
                  : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="ldapPort"
                data-testid="ldapPort"
                validated={
                  errors.ldapPort
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("ldapPort", { required: true })}
              />
            </FormGroup>

            <FormGroup
              label={t("point-ldapBindUsername")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.ldapBindUsername
                  ? ValidatedOptions.error
                  : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="ldapBindUsername"
                data-testid="ldapBindUsername"
                validated={
                  errors.ldapBindUsername
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("ldapBindUsername", { required: true })}
              />
            </FormGroup>

            <FormGroup
              label={t("point-ldapBindPassword")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.ldapBindPassword
                  ? ValidatedOptions.error
                  : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                type="password"
                id="ldapBindPassword"
                data-testid="ldapBindPassword"
                validated={
                  errors.ldapBindPassword
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("ldapBindPassword", { required: true })}
              />
            </FormGroup>

            <FormGroup
              label={t("point-ldapDomainName")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.ldapDomainName
                  ? ValidatedOptions.error
                  : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="ldapDomainName"
                data-testid="ldapDomainName"
                validated={
                  errors.ldapDomainName
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("ldapDomainName", { required: true })}
              />
            </FormGroup>

            <FormGroup label={t("point-ldapOrganizationalUnit")}>
              <KeycloakTextInput
                isRequired
                id="ldapOrganizationalUnit"
                data-testid="ldapOrganizationalUnit"
                {...register("ldapOrganizationalUnit")}
              />
            </FormGroup>

            <ActionGroup>
              <Button
                variant="primary"
                type="submit"
                data-testid="createProvider"
              >
                {t("common:save")}
              </Button>
              <Button
                variant="link"
                data-testid="cancel"
                component={(props) => (
                  <Link {...props} to={toLdapRoute({ realm })} />
                )}
              >
                {t("common:cancel")}
              </Button>
              <Button
                disabled={isDirty}
                variant="tertiary"
                data-testid="ldapTestConnection"
                onClick={testLdapConnection}
              >
                {t("point-ldapTestConnection")}
              </Button>
            </ActionGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
