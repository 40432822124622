import { Path } from "react-router-dom";
import { AppRouteObject } from "../../routes";
import CreateEmailTheme from "../pages/EmailThemesPages/CreateEmailTheme";
import EditEmailTheme from "../pages/EmailThemesPages/EditEmailTheme";
import EmailThemes from "../pages/EmailThemesPages/EmailTheme";
import { generateEncodedPath } from "../../utils/generateEncodedPath";

export type EmailThemeScopeParams = {
  realm: string;
  id: string;
};

const EmailThemesRoute: AppRouteObject = {
  path: "/:realm/emailThemes",
  element: <EmailThemes />,
  breadcrumb: (t) => t("point-email-themes"),
  handle: {
    access: ["anyone"],
  },
};

const CreateEmailThemesRoute: AppRouteObject = {
  path: "/:realm/emailThemes/create-emailTheme",
  element: <CreateEmailTheme />,
  breadcrumb: (t) => t("point-email-themes"),
  handle: {
    access: ["anyone"],
  },
};

const EditEmailThemesRoute: AppRouteObject = {
  path: "/:realm/emailThemes/:id",
  element: <EditEmailTheme />,
  breadcrumb: (t) => t("point-email-themes"),
  handle: {
    access: ["anyone"],
  },
};

export const emailThemesRoutes = [
  EmailThemesRoute,
  CreateEmailThemesRoute,
  EditEmailThemesRoute,
];

export const toEmailThemeScope = (
  params: EmailThemeScopeParams,
): Partial<Path> => {
  const path = EditEmailThemesRoute.path;
  return {
    pathname: generateEncodedPath(path, params),
  };
};
