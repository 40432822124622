import { useCallback, useEffect } from "react";

import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
  ValidatedOptions,
} from "@patternfly/react-core";

import { ViewHeader } from "../../../../components/view-header/ViewHeader";

import { FormProvider, useForm } from "react-hook-form";

import { FormAccess } from "../../../../components/form/FormAccess";
import { useTranslation } from "react-i18next";
import { KeycloakTextInput } from "../../../../components/keycloak-text-input/KeycloakTextInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useServerInfo } from "../../../../context/server-info/ServerInfoProvider";
import MultiSelect from "../../../components/MultiSelect";
import { adminClient } from "../../../../admin-client";
import { useRealm } from "../../../../context/realm-context/RealmContext";

import { useAlerts } from "../../../../components/alert/Alerts";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";
import { toIntegrationRoute } from "../../../routes/integrationsRoutes";
import { AdminEvents } from "../../../enums/AdminEvents";
import SingleSelect from "../../../components/Select";

export type CreateWebhookSchema = {
  name: string;
  url: string;
  type: "plain" | "jwt";
  loginEvents: string[];
  adminEvents: string[];
};

export type FormattedWebhookSchema = {
  name: string;
  url: string;
  type: "plain" | "jwt";
  eventsListeners: string[];
  adminEventsListeners: string[];
};

export default function EditWebhook() {
  const { webhookId } = useParams();
  const form = useForm<CreateWebhookSchema>({
    defaultValues: {
      name: "",
      url: "",
      type: "plain",
      loginEvents: [],
      adminEvents: [],
    },
  });

  const { enums } = useServerInfo();
  const navigate = useNavigate();
  const { addAlert, addError } = useAlerts();

  const eventTypeOptions = (enums?.["eventType"] ?? []).map((item) => ({
    label: item,
    value: item,
  }));

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = form;

  const { realm } = useRealm();

  const { t } = useTranslation("point");

  const onSubmit = async (data: CreateWebhookSchema) => {
    if (!webhookId) return;
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/integrations`;
    try {
      const formattedData = {
        uuid: webhookId,
        realmId: realmRep?.id,
        name: data.name,
        url: data.url,
        type: data.type,
        eventsListeners: data.loginEvents,
        adminEventsListeners: data.adminEvents,
      };
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(formattedData),
        headers: {
          "Content-Type": "application/json",
          ...getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      });
      navigate(toIntegrationRoute({ realm }));
      if (response.ok) {
        const successMessage = t("point-webhookEditedSuccessfuly");
        addAlert(successMessage, AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  const getIntegrations = useCallback(async () => {
    if (!webhookId) return;
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/integrations/${webhookId}/${realmRep?.id}`;
    const ingestionsResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(await adminClient.getAccessToken()),
      },
    });
    const ingestionsJson = await ingestionsResponse.json();
    if (ingestionsJson) {
      const loginEvents: string[] = JSON.parse(ingestionsJson.eventsListeners);
      const adminEvents: string[] = JSON.parse(
        ingestionsJson.adminEventsListeners,
      );
      reset({
        name: ingestionsJson.name,
        url: ingestionsJson.url,
        type: ingestionsJson.type,
        loginEvents: loginEvents,
        adminEvents: adminEvents,
      });
    }
  }, [realm, reset, webhookId]);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  return (
    <>
      <ViewHeader titleKey={t("point-editWebhook")} />

      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormGroup label={t("point-webhookId")}>
              <KeycloakTextInput isDisabled id="id" value={webhookId} />
            </FormGroup>
            <FormGroup
              label={t("point-webhookName")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.name ? ValidatedOptions.error : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="name"
                data-testid="name"
                validated={
                  errors.name
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("name", { required: true })}
              />
            </FormGroup>

            <FormGroup
              label={t("point-notificationUrl")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.url ? ValidatedOptions.error : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="url"
                data-testid="url"
                validated={
                  errors.url ? ValidatedOptions.error : ValidatedOptions.default
                }
                {...register("url", { required: true })}
              />
            </FormGroup>

            <SingleSelect
              label={t("point-type")}
              placeholder={t("point-type")}
              name="type"
              control={control}
              options={[
                { label: "JSON", value: "plain" },
                {
                  label: "JWT",
                  value: "jwt",
                },
              ]}
            />

            <MultiSelect
              label={t("point-loginEvents")}
              placeholder={t("point-loginEvents")}
              name="loginEvents"
              control={control}
              options={eventTypeOptions}
            />

            <MultiSelect
              label={t("point-adminEvents")}
              placeholder={t("point-adminEvents")}
              name="adminEvents"
              control={control}
              options={Object.values(AdminEvents).map((item) => ({
                label: item,
                value: item,
              }))}
            />

            <ActionGroup>
              <Button
                variant="primary"
                type="submit"
                data-testid="createProvider"
              >
                {t("common:save")}
              </Button>
              <Button
                variant="link"
                data-testid="cancel"
                component={(props) => (
                  <Link {...props} to={toIntegrationRoute({ realm })} />
                )}
              >
                {t("common:cancel")}
              </Button>
            </ActionGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
